<template>
    <header :class="{ 'fixed': fixed, 'hideSidebar': !sidebar.opened, 'noSidebar': !hasSidebar }" class="nav-header">
        <div class="navtop-left">
            <!-- <router-link to="/" v-if="hasLogo"> -->
            <router-link to="/order/list" v-if="hasLogo">
                <img class="logo-img" :src="require('@/assets/images/picture/LOGO@2x.png')" alt="" />
            </router-link>
            <template v-else>
                <hamburger v-if="hasSidebar" id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />
                <breadcrumb v-if="hasSidebar" id="breadcrumb-container" class="breadcrumb-container" />
            </template>
        </div>
        
        
        <ul class="nav" v-if="showNavItem">
            <li v-for="item of navList" :key="item.path" :class="{ active: $route.path == item.path }">
                <a @click="handleNavItem(item)">{{ item.name }}</a>
            </li>
        </ul>

        <div class="login-wrap">
            <button v-if="hasPlace" class="place-btn blue mr30" @click="gotoOrder">
                <i class="el-icon-edit-outline"></i>下单
            </button>
            <button v-if="hasService" class="place-btn blue mr30" @click="callService">
                <i class="el-icon-service"></i>联系客服
            </button>
            <!-- <div class="icon-box login">
                <div class="user">
                    <i class="el-icon-user"></i>{{ username }}
                    <p v-if="!isLogin">登录</p>
                </div>
            </div> -->
            <div class="user">
                <div class="user-text">
                    <!-- <i class="el-icon-user"></i> -->
                    <img v-if="avatar" class="avatar" :src="avatar" alt="" />
                    <!-- <img v-else class="avatar" :src="require('@/assets/images/icon/avatar.png')" alt="" /> -->
                    <img v-else class="avatar" :src="require('@/assets/images/icon/master.png')" alt="" />
                    <span>{{ username ? username : '登录' }}</span>
                </div>
                <div class="user-pop">
                    <template v-if="isLogin">
                        <div class="header">
                            <div class="user-name">{{username}}</div>
                        </div>
                        <div class="bottom">
                            <div class="btn"><router-link to="/order/list">用户中心</router-link></div>
                            <div class="line"></div>
                            <div class="btn" @click="logout()">退出登录</div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="header">
                            <div class="user-name">您还未登录</div>
                            <div class="user-tips">登录后即体验更多功能</div>
                        </div>
                        <div class="bottom">
                            <router-link to="/login?tabType=reg"><div class="btn">立即注册</div></router-link>
                            <div class="line"></div>
                            <router-link to="/login"><div class="btn">立即登录</div></router-link>
                        </div>
                    </template>
                </div>
            </div>
            
            <router-link to="/msg/message/" v-if="isLogin">
                <el-badge class="el-badge" :value="msg_count" :max="10" :hidden="msg_count == 0">
                    <!-- <i class="el-icon-bell"></i> -->
                    <svg-icon class="svg-icon" icon-class="bell" />
                </el-badge>
            </router-link>

        </div>
    </header>

</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        navInfo: Object,
        showNavItem: {
            type: Boolean,
            default: true
        },
        fixed: {
            type: Boolean,
            default: false
        },
        hasLogo: {
            type: [Boolean, String],
            default: false
        },
        hasService: {
            type: Boolean,
            default: false
        },
        hasPlace: {
            type: Boolean,
            default: true
        },
        hasSidebar: {
            type: Boolean,
            default: true
        },
    },
    data: () => ({
        banners: [],
        userInfo: {},
        // fixed: false,
        navList: [
            { path: '/', name: '首页', enable: true },
            { path: '/master', name: '师傅资源', enable: true },
            { path: '/service', name: '服务保障', enable: true },
            { path: '/activity', name: '优惠活动', enable: false },
            { path: '/settle', name: '师傅入驻', enable: true },
            { path: '/vip', name: '超级会员', enable: false },
            { path: '/news', name: '资讯中心', enable: true },
            { path: '/help', name: '帮助中心', enable: true },
        ]
    }),
    computed: {
        isLogin () {
            return this.username;
        },
        ...mapGetters({
            sidebar: 'sidebar',
            money: 'money',
            deduct_count: 'deduct_count',
            coupon_count: 'coupon_count',
            msg_count: 'msg_count',
            username: 'username',
            suserBase_name: 'suserBase_name',
            userId: 'userId',
            avatar: 'avatar'
        }),
    },
    created () {
        // if (this.navInfo) this.fixed = this.navInfo.fixed;
    },
    mounted () {
        this.bannerHeight = document.body.clientWidth / 4;
        window.addEventListener("scroll", this.onScroll, false);
        // console.log(this.comsys.checkBaiduPage(this.$route.path))
        if (this.$func.checkBaiduPage(this.$route.path)) {
            // console.log('aaaaaaaaaa')
            this.$func.initBaiduBridge()
        } else {
            // console.log('bbbbbbbbbbb')
            this.$func.removeBaiduBridge()
        }
    },
    destroy () {
        window.removeEventListener("scroll", this.onScroll);
    },
    methods: {

        toggleSideBar () {
            console.log('123')
            this.$store.dispatch('app/toggleSideBar')
        },

        handleNavItem (item) {
            if (item.enable == false) {
                this.$message({
                    type: 'success',
                    message: '敬请期待',
                    offset: 80
                });
                return
            }
            this.$router.push({ path: item.path });
        },

        // onScroll () {
        //     var scrollTop =
        //         window.pageYOffset ||
        //         document.documentElement.scrollTop ||
        //         document.body.scrollTop;
        //     scrollTop > 200 ? (this.fixed = true) : (this.fixed = false);
        // },

        logout () {
            this.$store.dispatch("user/logout").then((res) => {
                // this.$message.success("退出成功");
                this.$router.push({ path: "/" });
            });
        },

        gotoOrder () {
            if (!this.userId) {
                this.$router.push({ path: '/login/' })
                return;
            }
            this.$router.push({ path: '/place' })
        },

        
        callService () {
            this.$func.initBaiduBridge()
        },

    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";
// .user:hover {
//     .user-pop {
//         opacity: 1;
//         visibility: visible;
//     }
// }
.svg-icon {
    width: 24px;
    height: 24px;
//    width: 2em;
//    height: 2em;
//    vertical-align: -0.15em;
//    fill: currentColor;
//    overflow: hidden;
 }
.navtop-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.nav-header {
    // width: 1920px;
    // width: 100vw;
    display: flex;
    flex-direction: row;
    // justify-content: center;
    justify-content: space-between;
    // justify-content: flex-end;
    align-items: center;
    background: white;
    transition: all 0.5s;
    box-sizing: border-box;
    // padding: 0 100px;
    padding: 0 30px 0 0;
    
    &.fixed {
        position: fixed;
        top: 0;
        // left: 200px;
        // left: 0;
        right: 0;
        z-index: 9;
        // box-shadow: 0px 0px 8px 0px #e3e3e3;
        // transition: all 0.5s;
        width: calc(100% - #{$sideBarWidth} + 20px);
        transition: width 0.28s;
        &.hideSidebar {
            width: calc(100% - 80px + 20px) !important;;
        }
        &.noSidebar {
            width: 100%;
        }
    }

    .hamburger-container {
        line-height: 46px;
        height: 100%;
        // float: left;
        cursor: pointer;
        transition: background 0.3s;
        -webkit-tap-highlight-color: transparent;

        &:hover {
            background: rgba(0, 0, 0, 0.025);
        }
    }
    .logo-img {
        width: 95px;
        height: 46px;
        // margin: 0 30px 0 100px;
        margin: 0 30px 0 30px;
    }
    .nav {
        width: 60%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        li {
            width: 100px;
            border-top: 4px solid white;
            padding-bottom: 10px;
            text-align: left;
            &:hover,
            &.active {
                border-bottom: solid 4px #20ceab;
                color: #20ceab;
            }
            i {
                color: #ff0200;
            }
        }
    }

    .login-wrap {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        height: 60px;
        margin: 0 0 0 30px;
        font-size: 12px;
        
        .place-btn {
            width: 120px;
        }
        // .place-btn {
        //     font-size: 16px;
        //     outline: 0;
        //     border: none;
        //     background: #20ceab;
        //     color: white;
        //     border-radius: 3px;
        //     padding: 0.6rem 1.2rem;
        //     display: flex;
        //     align-items: center;
        //     cursor: pointer;
        //     margin-left: 20px;
        //     margin-right: 20px;
        //     width: 100px;
        //     i {
        //         font-size: 16px;
        //         margin-right: 0.3rem;
        //     }
        // }
        .user {
            // cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            // width: 160px;
            margin-right: 10px;
            font-size: 15px;
            font-weight: 500;
            color: #333;
            // i {
            //     color:#20ceab;
            //     margin-bottom: 0;
            //     margin-right: 10px;
            // }
            
            &:hover {
                // opacity: 1;
                // visibility: visible;
                .user-pop {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        .user-text {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-size: 13px;
            .avatar {
                border-radius: 100%;
                width: 32px;
                height: 32px;
                object-fit: cover;
                margin-right: 10px;
            }
        }
        .user-pop {
            opacity: 0;
            visibility: hidden;
            // opacity: 1;
            // visibility: visible;
            position: fixed;
            width: 300px;
            right: 20px;
            top: 50px;
            // opacity: 1;
            transition: 0.2s;
            z-index: 999;
            font-size: 14px;
            background: linear-gradient(180deg, #ffefde 0%, #ffffff 54px, #ffffff 100%);
            // background: linear-gradient(180deg, #b7f0e1 0%, #ffffff 100px, #ffffff 100%);
            width: 320px;
            // height: 260px;
            border-radius: 16px;
            box-shadow: 0 0 16px rgba(0, 0, 0, .2), inset 0 1px 2px #fff;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            background-color: #fff;
            .header {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: 24px;
                padding: 20px 40px 0;
                // background-color: transparent;
                .user-name {
                    // font-size: 18px;
                    font-size: 15px;
                    line-height: 30px;
                    // font-weight: 700;
                    color: #282d3c;
                }
                .user-tips {
                    font-size: 12px;
                }
            }
            .bottom {
                width: 100%;
                position: relative;
                height: 60px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-top: 1px solid #ccc;
                // .btn {
                //     height: 100%;
                //     flex: 0 0 50%;
                //     display: flex;
                //     justify-content: center;
                //     align-items: center;
                // }
                a {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .line {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    height: 20px;
                    border-left: 1px solid #d2d7e1;
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                }
                .btn {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    width: 50%;
                    height: 52px;
                    font-size: 14px;
                    line-height: 19px;
                    color: #282d3c;
                    cursor: pointer;
                    &:hover {
                        color:#20ceab;
                    }
                }
            }
        }
        .el-icon-bell {
            font-size: 16px;
        }
        
    }

}



</style>